import { apiSdkAtom } from '@/context/atoms/ApiSdk';
import { singletonAtom } from '@/context/atoms/helper/singletonAtom';
import { type SystemConfig } from '@ekkogmbh/apisdk';

export type SystemConfigRoot = SystemConfig & { image?: Blob };

export const systemConfigRootAtom = singletonAtom<Promise<SystemConfigRoot>>(async (get) => {
  const api = await get(apiSdkAtom);

  try {
    const config = await api.getSystemConfigRoot();

    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(config.enabled)) {
      const image = await api.getSystemConfigRootImage();
      // @TODO this step might need a refactoring if the whole config is not returned when the image is not available
      return {
        ...config,
        image,
      };
    }

    return config;
  } catch (e) {
    // do nothing
  }

  return {
    coordinate: null,
    enabled: false,
    imageType: null,
    workerUiPrimaryColor: null,
  };
});
