'use client';
import { EkkoLogo } from '@/components/common/EkkoLogo';
import { useThemeBreakpointResolve } from '@/components/useThemeBreakpointResolve';
import { systemConfigRootAtom } from '@/context/atoms/systemConfig';
import { SvgIcon } from '@mui/material';
import { useAtom } from 'jotai/index';
import Image from 'next/image';
import React from 'react';

interface AppLogoProps {
  maxWidth?: number;
  my?: number; // margin top and bottom
  fontSize?: number;
  forceSvgColor?: string;
}

export default function AppLogo({ maxWidth = 192, my = 0, fontSize, forceSvgColor }: AppLogoProps): React.JSX.Element {
  const [systemConfigRoot] = useAtom(systemConfigRootAtom);
  const themeBreakpointResolve = useThemeBreakpointResolve();

  // eslint-disable-next-line no-extra-boolean-cast
  if (Boolean(systemConfigRoot.enabled)) {
    if (systemConfigRoot.image !== undefined) {
      return (
        <Image
          src={URL.createObjectURL(systemConfigRoot.image)}
          alt="Logo"
          style={{
            maxWidth,
            marginTop: my * 8,
            marginBottom: my * 8,
          }}
        />
      );
    }
  }

  return (
    <SvgIcon
      component={EkkoLogo}
      color={'secondary'}
      fontSize={themeBreakpointResolve({
        xl: 'ekkoLogoXL',
        sm: 'ekkoLogoXS',
      })}
      sx={{
        ...(fontSize !== undefined ? { fontSize } : {}),
        ...(forceSvgColor !== undefined ? { color: forceSvgColor } : {}),
      }}
    />
  );
}
